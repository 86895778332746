export enum ScanJobStatus {
  WAITING = "WAITING",
  RUNNING = "RUNNING",
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
}

export type JobDetail = {
  ok: boolean;
  scanJob: ScanJob;
};

export type ScanJob = {
  url: string;
  startDate: string;
  results: CookiesResult;
  status: ScanJobStatus;
  progress: number;
  statusMessage: string;
  completeDate: string | undefined;
  totalPageScanned: number;
  totalCookiesFound: number;
  totalAnalyticsCookiesFound: number;
  totalMarketingCookiesFound: number;
  totalFunctionalCookiesFound: number;
  totalUnknownCookiesFound: number;
};

export type CookiesResult = { [key: string]: CookieDetail };

export type CookieDetail = {
  name: string;
  domain: string;
  path: string;
  detail: {
    cookieType: string;
    expiration: string;
    Platform: string | undefined;
    Category: string | undefined;
    Domain: string | undefined;
    Description: string | undefined;
    "Retention period": string | undefined;
    "Data Controller": string | undefined;
  };
};
