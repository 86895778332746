import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_cookie = _resolveComponent("header-cookie")!
  const _component_cookie_scanner_view = _resolveComponent("cookie-scanner-view")!
  const _component_n_notification_provider = _resolveComponent("n-notification-provider")!
  const _component_n_config_provider = _resolveComponent("n-config-provider")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_n_config_provider, { "theme-overrides": _ctx.themeOverrides }, {
      default: _withCtx(() => [
        _createVNode(_component_n_notification_provider, null, {
          default: _withCtx(() => [
            _createVNode(_component_header_cookie),
            _createVNode(_component_cookie_scanner_view)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["theme-overrides"])
  ]))
}