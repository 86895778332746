
import { Options, Vue } from "vue-class-component";
import { NNotificationProvider } from "naive-ui";
import CookieScannerView from "./CookieScannerView.vue";
import HeaderCookie from "./components/Header.vue";
import { NConfigProvider } from "naive-ui";

@Options({
  components: {
    CookieScannerView,
    NNotificationProvider,
    HeaderCookie,
  },
})
export default class App extends Vue {
  themeOverrides = {
    common: {
      borderHover: "1px solid #F26634",
    },
  };
}
