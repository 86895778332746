
import { CookiesResult } from "@/common";
import { Options, Vue } from "vue-class-component";
import {
  NCard,
  NBadge,
  NSpace,
  NLayout,
  NLayoutHeader,
  NLayoutContent,
} from "naive-ui";
import moment from "moment";
import CompanyEmailValidator from "company-email-validator";
import { useCookies } from "vue3-cookies";

import CookieScanner from "../services/CookieScanner";

const { cookies } = useCookies();

@Options({
  props: {
    cookies: {},
    url: "",
    totalPageScanned: 0,
    totalCookiesFound: 0,
    totalMarketingCookiesFound: 0,
    totalAnalyticsCookiesFound: 0,
    totalFunctionalCookiesFound: 0,
    totalUnknownCookiesFound: 0,
    dateGenerate: "",
    jobId: "",
  },
  components: {
    NCard,
    NBadge,
    NSpace,
    NLayout,
    NLayoutHeader,
    NLayoutContent,
  },
  watch: {
    phone() {
      this.disablePhoneButton = this.phone === "";
    },
    firstName() {
      this.disableButton = !(this.firstName && this.lastName && this.email);
    },
    lastName() {
      this.disableButton = !(this.firstName && this.lastName && this.email);
    },
    email() {
      if (CompanyEmailValidator.isCompanyEmail(this.email)) {
        this.emailError = "";
        this.disableButton = !(this.firstName && this.lastName && this.email);
      } else {
        this.emailError = "กรุณากรอกอีเมลของบริษัท";
      }
    },
  },
  computed: {
    typeOfResult() {
      if (
        this.totalAnalyticsCookiesFound > 0 ||
        this.totalMarketingCookiesFound > 0
      ) {
        return "dangerous";
      } else if (this.totalUnknownCookiesFound > 0) {
        return "warning";
      }
      return "success";
    },
  },
})
export default class CookieDetail extends Vue {
  cookies!: CookiesResult;
  url!: string;
  totalPageScanned!: number;
  totalCookiesFound!: number;
  totalMarketingCookiesFound!: number;
  totalAnalyticsCookiesFound!: number;
  totalFunctionalCookiesFound!: number;
  totalUnknownCookiesFound!: number;
  dateGenerate!: string;
  indexInRedisLead!: any;
  jobId!: string;

  firstName = "";
  lastName = "";
  email = "";
  emailError = "";

  phone = "";
  disablePhoneButton = true;
  isShowThankyouWord = false;

  // check domain is "sable.asia" or not if yes no show modal by default
  isShowModal = this.url?.includes("sable.asia") ? false : true;
  // isShowModal = false;
  // isShowModal = true;
  disableButton = true;

  bannerData = {
    dangerous: {
      header: "เว็บไซต์นี้กำลังเก็บข้อมูลแบบผิดกฎหมาย",
      description:
        "เว็บไซต์นี้กำลังเก็บข้อมูลแบบผิดกฎหมาย GDPR/PDPA หากอยากเก็บข้อมูลพฤติกรรมผู้ใช้แบบถูกกฎหมาย",
      "bg-color": "#FFFBFA",
      "border-color": "#FDA29B",
      "text-color": "#B42318",
      "icon-bg": "#FEE4E2",
      contactBox: true,
      buttonText: "ติดตั้ง cookie consent ที่ถูกต้องตามกฏหมาย",
    },
    warning: {
      header: "เว็บไซต์นี้อาจจะเก็บข้อมูลแบบผิดกฎหมาย",
      description:
        "อาจจะมีบางส่วนของเว็บไซต์ที่เก็บข้อมูลแบบผิดกฎหมาย GDPR/PDPA หากอยากเก็บข้อมูลพฤติกรรมผู้ใช้แบบถูกกฎหมาย",
      "bg-color": "#FFFCF5",
      "border-color": "#FEC84B",
      "text-color": "#B42318",
      "icon-bg": "#FEF0C7",
      contactBox: true,
      buttonText: "ติดตั้ง cookie consent ที่ถูกต้องตามกฏหมาย",
    },
    success: {
      header: "เว็บไซต์นี้ปลอดภัย",
      description:
        "คุณสบายใจได้เลยไม่มี cookie ที่ละเมิด GDPR/PDPA แต่ถ้าอยากให้มั่นใจติดต่อให้เราช่วยตรวจสอบได้",
      "bg-color": "#F6FEF9",
      "border-color": "#027A48",
      "text-color": "#027A48",
      "icon-bg": "#D1FADF",
      contactBox: false,
      buttonText: "สอบถามเพิ่มเติม",
    },
  };

  isShowPhoneField = false;

  showPhoneField() {
    this.isShowPhoneField = true;
  }
  formatDate(date: string) {
    return moment(date).format("DD-MM-YYYY HH:mm:ss zz");
  }

  async saveContact() {
    const result = await CookieScanner.saveContact({
      jobId: this.jobId,
      url: this.url,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
    });
    this.indexInRedisLead = result?.addLead;

    this.isShowModal = false;
  }

  async submitPhoneNumber() {
    this.isShowThankyouWord = true;

    if (this.indexInRedisLead || this.indexInRedisLead === 0) {
      const result = await CookieScanner.updateContact({
        jobId: this.jobId,
        phone: this.phone,
      });
    }
  }

  openLink(url: string): void {
    const visitorId = cookies.get("s_visitorId");

    if (visitorId) {
      url = `${url}?s_visitorId=${visitorId}`;
    }
    window.open(url, "_blank");
  }
}
