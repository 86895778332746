import { JobDetail } from "@/common";
import axios from "axios";

class CookieScannerService {
  private _APIPath: string | undefined = "";

  get APIPath(): string | undefined {
    if (!this._APIPath) this._APIPath = process.env.VUE_APP_API;
    if (!this._APIPath) {
      console.error("API Path is not set!");
    }
    return this._APIPath;
  }

  async createScanJob(
    url: string
  ): Promise<{ ok: boolean; jobId: string } | undefined> {
    try {
      console.log(`${this.APIPath}/v1/scan`);
      const createScanJobResult = await axios({
        method: "post",
        url: `${this.APIPath}/v1/scan`,
        data: { url },
      });
      const { data } = createScanJobResult;
      console.log("Create job complete");
      // console.log(data);
      return data;
    } catch (e: any) {
      console.log("Create scan job failed");
      e.title = "Cookie Scanning failed";
      this.convertErrorToMessage(e);
      console.log(e);
      throw e;
    }
  }

  async getJob(jobId: string): Promise<JobDetail | undefined> {
    try {
      console.log(`${this.APIPath}/v1/scan`);
      const createScanJobResult = await axios({
        method: "get",
        url: `${this.APIPath}/v1/job/${jobId}`,
      });
      const { data } = createScanJobResult;
      console.log("Get scan job complete");
      // console.log(data);
      return data;
    } catch (e: any) {
      console.log("Get scan job failed");
      e.title = "Fetching Cookie scan result";
      this.convertErrorToMessage(e);
      throw e;
    }
  }

  async saveContact(leadData: any): Promise<any | undefined> {
    try {
      const { firstName, lastName, email, url, jobId } = leadData;
      const savedContact = await axios({
        method: "post",
        url: `${this.APIPath}/v1/lead`,
        data: {
          id: jobId,
          url,
          firstName,
          lastName,
          email,
        },
      });
      const { data } = savedContact;
      // console.log(data);
      return data;
    } catch (e: any) {
      console.log("Get scan job failed");
      e.title = "Fetching Cookie scan result";
      this.convertErrorToMessage(e);
      throw e;
    }
  }

  async updateContact(leadData: any): Promise<any | undefined> {
    try {
      const { jobId, phone } = leadData;
      const updatedContact = await axios({
        method: "patch",
        url: `${this.APIPath}/v1/lead`,
        data: {
          id: jobId,
          phone,
        },
      });
      const { data } = updatedContact;
      // console.log(data);
      return data;
    } catch (e: any) {
      console.log("Get scan job failed");
      e.title = "Fetching Cookie scan result";
      this.convertErrorToMessage(e);
      throw e;
    }
  }

  convertErrorToMessage(e: any) {
    switch (e.code) {
      case "ERR_NETWORK":
        e.description =
          "เซิฟเวอร์ของหนูกำลังทำงานอย่างหนัก อีก 5 นาที คุณพี่ลองอีกครั้งนะคะ";
        break;
      default:
        e.description = "ขณะนี้ระบบอาจจะกำลังปรับปรุง ลองอีกครั้งนะคะคุณพี่";
    }
  }
}

export default new CookieScannerService();
