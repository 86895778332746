
import { Options, Vue } from "vue-class-component";
import CookieDetail from "./components/CookieDetail.vue";
import {
  NButton,
  NInput,
  NSpace,
  NProgress,
  NSpin,
  useThemeVars,
  useNotification,
} from "naive-ui";
import { useCookies } from "vue3-cookies";
import { ref, watchEffect } from "vue";

import CookieScanner from "./services/CookieScanner";
import { ScanJobStatus } from "./common";
import moment from "moment";
import isURL from "validator/lib/isURL";

import ScannerHeader from "./components/Scanner/ScannerHeader.vue";
import Scanning from "./components/Scanning.vue";

const { cookies } = useCookies();

@Options({
  components: {
    CookieDetail,
    NButton,
    NInput,
    NSpace,
    NProgress,
    NSpin,
    ScannerHeader,
    Scanning,
  },
})
export default class CookieScannerView extends Vue {
  message = ref(cookies.get("message"));
  targetUrl = "";
  cookies: any = undefined;
  jobId = "";
  fetchingJobInterval: any = undefined;
  scanJob: any = undefined;

  //frontend controller variable
  disableInput = false;
  disableButton = false;
  showResult = false;
  themeVars = useThemeVars();
  notification: any = useNotification();

  state = "SCANNER"; // SCANNER, SCANNING, RESULT

  reset() {
    this.disableInput = true;
    this.showResult = false;
    this.scanJob = undefined;
    this.cookies = undefined;
  }

  validateURL(url: string): boolean {
    return isURL(url, {
      require_valid_protocol: true,
      require_protocol: true,
    });
  }

  async createScannerJob() {
    if (!this.disableButton) {
      return;
    }
    if (!this.validateURL(this.targetUrl)) {
      this.notifyError(
        "พี่ขา พี่กรอก URL ไม่ถูกต้องนะคะ",
        "กรุณากรอก URL ตามรูปแบบที่หนูเข้าใจนะคะพี่ โดยต้องมี https:// หรือ http:// นะคะ"
      );
      return;
    }
    this.reset();
    this.state = "SCANNING";
    try {
      const result = await CookieScanner.createScanJob(this.targetUrl);
      if (result && result.ok) {
        this.jobId = result.jobId;
        this.fetchScannerJob();
      }
      this.fetchingJobInterval = setInterval(this.fetchScannerJob, 5000);
    } catch (e: any) {
      this.stopFetchScannerJob();
      this.notifyError(e.title, e.description);
    }
  }

  async fetchScannerJob() {
    if (!this.jobId) return this.stopFetchScannerJob();
    try {
      console.log("Fetch job");
      const jobDetail = await CookieScanner.getJob(this.jobId);
      if (jobDetail) {
        const { scanJob } = jobDetail;
        this.scanJob = scanJob;
        switch (scanJob.status) {
          case ScanJobStatus.COMPLETE:
            // Job is done, stop fetching
            this.cookies = scanJob.results;
            this.stopFetchScannerJob(true);
            this.showResult = true;
            break;
          case ScanJobStatus.FAILED:
            this.stopFetchScannerJob();
            this.notifyError(
              "ระบบตรวจสอบเว็บไซต์เกิดข้อขัดข้อง",
              "อีกสัก 5 นาทีกลับมาลองอีกครั้งนะคะ"
            );
            break;
        }
      } else {
        // Job missing
        this.stopFetchScannerJob();
        this.notifyError(
          "เกิดเหตุขัดแข้งบางอย่าง",
          "อีกสัก 5 นาทีกลับมาลองอีกครั้งนะคะ"
        );
      }
      await this.$nextTick();
      this.$forceUpdate();
    } catch (e: any) {
      this.stopFetchScannerJob();
      this.notifyError(e.title, e.description);
    }
  }

  stopFetchScannerJob(isSuccess = false) {
    if (isSuccess) {
      this.state = "RESULT";
    } else {
      this.state = "SCANNING";
    }
    this.disableInput = false;
    clearInterval(this.fetchingJobInterval);
  }

  notifyError(title: string, message: string) {
    this.state = "SCANNER";
    this.notification.create({
      title,
      // description: "From the Beach Boys",
      content: message,
      meta: moment().format("LTS"),
      duration: 3000,
      // onAfterLeave: () => {},
    });
  }

  openLink(url: string): void {
    const visitorId = cookies.get("s_visitorId");

    if (visitorId) {
      url = `${url}?s_visitorId=${visitorId}`;
    }
    window.open(url, "_blank");
  }
}
