
import { Options, Vue } from "vue-class-component";
import Lottie from "lottie-web/build/player/lottie";

@Options({
  props: {
    percentage: 0,
  },
})
export default class Scanning extends Vue {
  scanning = require("../assets/scanning.js");

  mounted() {
    Lottie.loadAnimation({
      container: document.getElementById("searching")!,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: this.scanning.default,
    });
  }
}
