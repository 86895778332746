
import { Vue, Options } from "vue-class-component";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export default class Header extends Vue {
  lawmanLink = "https://lawman.in.th/pdpa";

  refreshPage() {
    window.location.reload();
  }

  openLawman() {
    const visitorId = cookies.get("s_visitorId");

    if (visitorId) {
      this.lawmanLink = `https://lawman.in.th/pdpa/?s_visitorId=${visitorId}`;
    }
    window.open(this.lawmanLink, "_blank");
  }
}
