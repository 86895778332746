<template>
  <div class="flex flex-col justify-center items-center">
    <div class="flex justify-center items-center space-x-2 lg:space-x-4">
      <span
        class="font-semibold text-4xl lg:text-6xl leading-10 lg:leading-[5.5rem]"
      >
        Cookie
      </span>
      <span>
        <img
          src="/images/cookie.svg"
          class="w-[1.5rem] h-[1.5rem] lg:w-[2.5rem] lg:h-[2.5rem]"
        />
      </span>
      <span
        class="font-bold text-4xl lg:text-6xl leading-10 leading-[5.5rem] text-[#F26634]"
      >
        Scanner
      </span>
      <span class="bg-[#F26634] p-2 rounded">
        <img
          src="/images/search.svg"
          class="w-[.875rem] h-[.875rem] lg:w-[1.5rem] lg:h-[1.5rem]"
        />
      </span>
    </div>
    <div
      class="w-[21.25rem] lg:w-auto text-[#667085] font-normal text-sm lg:text-xl leading-5 lg:leading-7"
    >
      เว็บไซต์ของคุณปฏิบัติตามกฏหมาย GDPR/PDPA หรือไม่?
      ตรวจสอบเว็บไซต์ของคุณได้ที่นี่
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
